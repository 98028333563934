import {
  Flex,
  Input,
  List,
  ListItem,
  PopoverBody,
  PopoverFooter,
  PopoverHeader,
  keyframes,
  Box,
} from '@chakra-ui/react';
import styles from './select-filter.module.scss';
import {
  ChangeEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from 'react';
import { TalentDiscoveryFilterOption } from './types';
import { SelectFooter } from './select-footer';

export const SelectFilter = ({
  filterOptions,
  setSelectedFilter,
  closeMenu,
}: {
  filterOptions: TalentDiscoveryFilterOption[];
  setSelectedFilter: Dispatch<
    SetStateAction<TalentDiscoveryFilterOption | null>
  >;
  closeMenu: () => void;
}) => {
  const [search, setSearch] = useState('');
  const [optionsStack, setOptionsStack] = useState<
    TalentDiscoveryFilterOption[][]
  >([filterOptions]);

  const handleSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearch(e.target.value);
  };

  const currentOptions = optionsStack[optionsStack.length - 1];

  const filteredFilterOptions = currentOptions.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  const handleFilterSelect = (filter: TalentDiscoveryFilterOption) => {
    if (filter.children) {
      setOptionsStack([...optionsStack, filter.children]);
      setSearch('');
    } else {
      setSelectedFilter(filter);
    }
  };

  const handleBack = () => {
    if (optionsStack.length > 1) {
      setOptionsStack(optionsStack.slice(0, optionsStack.length - 1));
      setSearch('');
    }
  };

  return (
    <>
      <PopoverHeader padding={0} borderBottomWidth={0}>
        <Input
          placeholder="Select a filter"
          size="sm"
          className={styles.input}
          onChange={handleSearch}
          value={search}
        />
      </PopoverHeader>
      <PopoverBody padding={0}>
        <List
          pb={2}
          border="1px solid #E5EBF1"
          borderTopWidth={0}
          overflowY="scroll"
          maxHeight="400px"
        >
          {filteredFilterOptions.length === 0 && (
            <ListItem paddingY={2} textAlign={'center'} opacity={0.5}>
              No options
            </ListItem>
          )}

          {filteredFilterOptions.map((option) => (
            <AnimatedGradientBox active={option.gradientBackground}>
              <ListItem
                role="button"
                key={option.value}
                cursor={'pointer'}
                onClick={() => handleFilterSelect(option)}
                paddingX={3}
                paddingY={1.5}
                _hover={{
                  bg: option.gradientBackground ? '#9AF2A833' : 'gray.100',
                }}
                data-testid="select-filter-option"
                className={styles.gradientBackground}
              >
                <Flex alignItems="center" gap={2}>
                  {option.icon}
                  {option.label}
                </Flex>
              </ListItem>
            </AnimatedGradientBox>
          ))}
        </List>
      </PopoverBody>
      <PopoverFooter padding={'8px 0 0'} borderTopWidth={0}>
        <SelectFooter
          onClose={closeMenu}
          onBack={optionsStack.length > 1 ? handleBack : undefined}
          isAddDisabled
        />
      </PopoverFooter>
    </>
  );
};

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const AnimatedGradientBox = ({
  children,
  active = false,
}: {
  children: ReactNode;
  active?: boolean;
}) => {
  if (!active) {
    return children;
  }

  return (
    <Box
      bgGradient="linear(to-r, #F1F5F933, #6AAEFF33, #6CF2F233, #9AF2A833)"
      backgroundSize="400% 400%"
      animation={`${gradientShift} 8s ease infinite`}
    >
      {children}
    </Box>
  );
};
