import { Button, Flex, keyframes } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';
import { Icon, Text } from '@chakra-ui/react';
import { FiSliders } from 'react-icons/fi';
import { TalentDiscoveryFilterPopover } from './td-filter-popover';
import { useTalentDiscoveryFilter } from './td-filter-provider';
import { FilterTag } from './filter-tag';
import { FILTER_OPTIONS } from './filter-configuration-options';
import { FilterSetSaveMenu } from '@revelio/filtering';
import { View } from '@revelio/data-access';
import { SingleValue } from 'react-select';
import { Option } from '@revelio/filtering';
import { AiSearchIcon } from './ai-search-icon';
import { CloseIcon } from '@chakra-ui/icons';

const FilterButton = forwardRef(
  (props: { isDisabled?: boolean }, ref: LegacyRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        ref={ref}
        colorScheme="gray"
        borderRadius="4px"
        size="sm"
        paddingX={3}
        height={6}
        backgroundColor="transparent"
        fontSize={'xs'}
        fontWeight={400}
        color="navyBlue.500"
        leftIcon={
          <Icon as={FiSliders} boxSize={3.5} transform="rotate(90deg)" />
        }
        data-testid="filter-menu-btn"
        isDisabled={props.isDisabled}
      >
        Filter
      </Button>
    );
  }
);

export type TalentDiscoveryFilterMenuProps = {
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
};

export const TalentDiscoveryFilterMenu = ({
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterMenuProps) => {
  const { state, dispatch, isAiSearchLoading } = useTalentDiscoveryFilter();

  return (
    <Flex wrap="wrap" fontSize="sm" rowGap={2.5}>
      <AiGenerating />
      {!isAiSearchLoading &&
        state.filters?.map((filter, index) => (
          <FilterTag
            key={`${filter.name}-${index}`}
            filterOptions={FILTER_OPTIONS}
            filter={filter}
            dispatch={dispatch}
            loading={isAiSearchLoading}
          />
        ))}
      {!isAiSearchLoading && (
        <>
          <TalentDiscoveryFilterPopover
            trigger={<FilterButton isDisabled={isAiSearchLoading} />}
            onSubmitSavedFilterSet={onSubmitSavedFilterSet}
          />
          <FilterSetSaveMenu
            view={View.TalentDiscovery}
            isDisabled={isAiSearchLoading}
          />
        </>
      )}
    </Flex>
  );
};

const AiGenerating = () => {
  const {
    isAiSearchLoading,
    handleStopAiSearch,
    setShouldTriggerOpenAiSearch,
  } = useTalentDiscoveryFilter();

  if (!isAiSearchLoading) {
    return null;
  }

  const handleClose = () => {
    handleStopAiSearch();
    setShouldTriggerOpenAiSearch(true);
  };

  return (
    <Flex
      bgGradient="linear(to-r, #2674D1, #5AD0D0, #9ADF7A)"
      borderRadius="4px"
      p="2px"
      animation={`${gradientShift} 8s ease infinite`}
      backgroundSize="400% 400%"
    >
      <Flex
        alignItems="center"
        gap={2}
        px={2}
        py={1}
        bgGradient="linear(to-r, #2F99FB, #6CF2F2, #B5FF9B)"
        backgroundSize="400% 400%"
        animation={`${gradientShift} 8s ease infinite`}
        borderRadius="3px"
        overflow="hidden"
      >
        <AiSearchIcon />
        <Text
          fontSize={12}
          color="navyBlue.500"
          fontWeight={600}
          lineHeight={1}
          mr={4}
        >
          Generating...
        </Text>
        <CloseIcon
          cursor="pointer"
          onClick={handleClose}
          data-testid="stop-ai-search"
          fontSize={8}
          color="#96A0B4"
        />
      </Flex>
    </Flex>
  );
};

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
